import React, {Component} from 'react';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import CounterStyle from './counterComponent.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inc_quantity, dec_quantity, clear_cart } from '../redux/cart.redux';
import { connect } from 'react-redux';

class CounterComponent extends Component {
    constructor(props) {
        super(props);
    }

    get_all_quantity = () => {
        let q = 0;
        for (let i=0; i<this.props.cart.items_in_cart.length; i++) {
            q += this.props.cart.items_in_cart[i].quantity;
        }
        return q;
    }

    render() {
        return (
            <div>
                <div className={CounterStyle.container}>
                    <button className={CounterStyle.minusBtn} onClick={() => {
                        if (this.get_all_quantity() > 1) {
                            this.props.dec_quantity(this.props.index)
                        } else if (confirm('Your cart will be empty, and your promo code will be lost, are you sure?')){
                            this.props.clear_cart()
                        }
                    }}>
                        <FontAwesomeIcon icon={faMinus} size="1x" />
                    </button>
                    <div className={CounterStyle.quantity}>
                        {this.props.quantity}
                    </div>
                    <button className={CounterStyle.addBtn} onClick={() => {this.props.inc_quantity(this.props.index)}}>
                        <FontAwesomeIcon icon={faPlus} size="1x" />
                    </button>
                </div>
            </div>
        )
    }
}

const mapStatetoProps = (state) => {return {cart: state.cart}};
const actionCreators = { dec_quantity, inc_quantity, clear_cart };
export default connect(mapStatetoProps, actionCreators)(CounterComponent);