import React, { Component } from 'react';
import { connect } from 'react-redux';
import { remove_from_cart, subtotal_updated, total_updated } from '../redux/cart.redux';

import CartStyle from './cart.module.css'
import { Grid } from '@material-ui/core';
import Counter from '../components/counterComponent'
import Layout from '../components/Layout';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard, faCcAmex, faCcDiscover, faCcStripe } from '@fortawesome/free-brands-svg-icons';
import { navigate } from 'gatsby';
import Banner from '../components/shoppingBanner'
import CheckoutFlowIndicator from '../components/checkoutFlowIndicator'

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {isMobile: true};
        this.handleResize = this.handleResize.bind(this);
        this.proceedToCheckOut = this.proceedToCheckOut.bind(this);
        this.count = 0;
        this.total = 0;
    }

    handleResize = e => {
        const windowSize = window.innerWidth;
        this.setState(state => ({...state, isMobile: windowSize < 1000}));
    }

    componentDidMount() {
        const windowSize = window.innerWidth;
        this.setState(state => ({...state, isMobile: windowSize < 1000}));
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    proceedToCheckOut() {
        this.props.subtotal_updated(this.total);
        this.props.total_updated(this.total);
        navigate('/checkout');
    }




    render() {
        const all_item = this.props.cart.items_in_cart;
        const amount_of_items = function() {
            var count = 0;
            var total = 0;
            for (var i=0; i < all_item.length; i++) {
                count += all_item[i].quantity;
                total += all_item[i].quantity * all_item[i].price;
            }
            this.count = count;
            this.total = total;
            return {count, total};
        }.bind(this);
        const amount = amount_of_items();
        
        
        const items = this.props.cart.items_in_cart.map((item, i) => {
            return (
                <div className={CartStyle.gridCotainer} key={i}>
                    <Grid container spacing={3} justify='flex-end' alignItems='center'>
                        <Grid container xs={this.state.isMobile ? 8: 10} justify='flex-start' alignItems='center'>
                            <Grid item xs={this.state.isMobile ? 4 : 2} style={{textAlign: 'center'}}>
                                <img style={{maxHeight: '160px', marginLeft: this.state.isMobile ? 0 : '13px'}} src={item.img} />
                            </Grid>
                            <Grid item xs={8}>
                                <div style={this.state.isMobile ? {marginLeft: '32px'} : {marginLeft: '4rem'}}>
                                    <p style={{margin: '0 0 4px 0', textTransform:'uppercase'}}>{`${item.name}`}</p>
                                    <p style={{fontWeight:300, margin:0, lineHeight: 1.2, fontSize: '14px'}}>{`${item.description}`}</p>
                                    <p style={{fontSize: '1.71429em', fontFamily:`Montserrat,sans-serif`, color: '#7a7a7a'}}>{`${item.currency}${item.price*item.quantity}`}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={this.state.isMobile ? 4 : 2}>
                            <Counter quantity={item.quantity} index={i} isMobile={this.state.isMobile}/>
                        </Grid>
                    </Grid>
                </div>
            )
        });


        const gridFooter=(
            <div style={amount.count > 0 ? null : {display:'none'}}>
                <div style={{width: '100%', textAlign: 'right'}}>
                    <p style={{fontWeight:700, margin:'0px', display: 'inline'}}>Total</p>
                    <p style={{fontWeight:700, margin:'0px', marginLeft: '12px', fontFamily: `Montserrat,sans-serif`, display:'inline'}}>{`$${amount.total}`}</p>
                    {this.props.cart.needs_shipping ? <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em'}}>Taxes and shipping calculated at checkout</p> : null}
                 </div>

                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <div style={{fontWeight: 300, color: 'grey', marginBottom: '12px'}}>
                            <FontAwesomeIcon icon={faLock} size="1x" style={{marginRight: '8px'}}/>
                            Secure Checkout
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={1.2}>
                                <FontAwesomeIcon icon={faCcVisa} size="3x" color="rgb(38, 51, 122)" style={{marginRight: '8px'}}/>
                            </Grid>
                            <Grid item xs={1.2}>
                                <FontAwesomeIcon icon={faCcMastercard} size="3x" color="rgb(0, 0, 102)" style={{marginRight: '8px'}}/>
                            </Grid>
                            <Grid item xs={1.2}>
                                <FontAwesomeIcon icon={faCcAmex} size="3x" color="rgb(0, 114, 206)" style={{marginRight: '8px'}}/>
                            </Grid>
                            <Grid item xs={1.2}>
                                <FontAwesomeIcon icon={faCcDiscover} size="3x" color="rgb(12, 30, 150)" style={{marginRight: '8px'}}/>
                            </Grid>
                            <Grid item xs={1.2}>
                                <FontAwesomeIcon icon={faCcStripe} size="3x" color="rgb(0, 69, 124)" style={{marginRight: '8px'}}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={CartStyle.checkoutBtn}>
                            <button onClick={()=>{this.proceedToCheckOut()}}>
                                Proceed to check out
                            </button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )


        const mobileGridFooter = (
            <div style={amount.count > 0 ? null : {display:'none'}}>
                <div style={{width: '100%', textAlign: 'right'}}>
                    <p style={{fontWeight:700, margin:'0px', display: 'inline'}}>Total</p>
                    <p style={{fontWeight:700, margin:'0px', marginLeft: '12px', fontFamily: `Montserrat,sans-serif`, display:'inline'}}>{`$${amount.total}`}</p>
                    <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em'}}>Taxes and shipping calculated at checkout</p>
                 </div>
                    <div className={CartStyle.checkoutBtn}>
                        <button onClick={()=>{this.proceedToCheckOut()}}>
                            Proceed to check out
                        </button>
                    </div>
                <div style={{fontWeight: 300, color: 'grey', marginBottom: '12px'}}>
                    <FontAwesomeIcon icon={faLock} size="1x" style={{marginRight: '8px'}}/>
                    Secure Checkout
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={1.2}>
                        <FontAwesomeIcon icon={faCcVisa} size="3x" color="rgb(38, 51, 122)" style={{marginRight: '8px'}}/>
                    </Grid>
                    <Grid item xs={1.2}>
                        <FontAwesomeIcon icon={faCcMastercard} size="3x" color="rgb(0, 0, 102)" style={{marginRight: '8px'}}/>
                    </Grid>
                    <Grid item xs={1.2}>
                        <FontAwesomeIcon icon={faCcAmex} size="3x" color="rgb(0, 114, 206)" style={{marginRight: '8px'}}/>
                    </Grid>
                    <Grid item xs={1.2}>
                        <FontAwesomeIcon icon={faCcDiscover} size="3x" color="rgb(12, 30, 150)" style={{marginRight: '8px'}}/>
                    </Grid>
                    <Grid item xs={1.2}>
                        <FontAwesomeIcon icon={faCcStripe} size="3x" color="rgb(0, 69, 124)" style={{marginRight: '8px'}}/>
                    </Grid>
                </Grid>
            </div>
        )

        return (
            <Layout hideSocial={true} hideCart={true}>
                <Banner isMobile={this.state.isMobile}/>
                <div className={CartStyle.container}>
                    <CheckoutFlowIndicator location='cart'/>
                    <div className={CartStyle.title}>
                        <h2>
                            {amount.count > 0 ? `You have ${amount.count} items in cart` : 'Cart'}
                        </h2>
                    </div>
                    <hr/>
                    {items.length > 0 ? items : <p style={{fontWeight: 300, width: '100%', textAlign:'center'}}>Your cart is empty</p>}
                    <hr/>
                    {this.state.isMobile ? mobileGridFooter : gridFooter}
                </div>
            </Layout>
        )
    }
}

const mapStatetoProps = (state) => {return {cart: state.cart}};
const actionCreators = { remove_from_cart, subtotal_updated, total_updated };
export default connect(mapStatetoProps, actionCreators)(Cart);